/* eslint-disable max-len */
/* eslint-disable no-irregular-whitespace */
/* eslint-disable arrow-body-style */
import React from 'react';
import styles from './Contact.module.scss';

const SlideContact = () => {
  return (
    <small className={styles.slider}>
      Call Us: +234 818 264 3531 | Email Us: info@avilahinteriors.com | Working Hours: 09:00am-06:00pm
    </small>
  );
};

export default SlideContact;
