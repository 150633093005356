import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import {
  motion, AnimatePresence, easeIn, easeInOut,
} from 'framer-motion';
import livingRm from '../../images/livingroom.jpg';
import furn from '../../images/furnt.png';
import decor from '../../images/decorb.png';
import bedRm from '../../images/bedroom.jpg';
import bDecor from '../../images/bDecor.png';
import success from '../../images/success.png';
import qFurn from '../../images/qFurn.png';
import styles from './styles/LandingPage.module.scss';

const heroBg = [
  {
    title: 'Innovative interior design solution',
    bg: livingRm,
    action: 'Book a Consultation',
    path: '/consultation/interior-design',
  },
  {
    title: 'Commitment to quality',
    bg: bedRm,
    action: 'Book a Consultation',
    path: '/consultation/interior-design',
  },
  {
    title: 'Excellent customer service',
    bg: success,
    action: 'Book a Consultation',
    path: '/services',
  },
  {
    title: 'Quality furniture-making',
    bg: qFurn,
    action: 'Order Furniture',
    path: 'https://shop.avilahinteriors.com/bespoke-furniture',
  },
  {
    title: 'Beautiful interior decor items',
    bg: bDecor,
    action: 'Shop now',
    path: 'https://shop.avilahinteriors.com',
  },
  {
    title: 'Bespoke furniture-making',
    bg: furn,
    action: 'Order Furniture',
    path: '/consultation/furniture-making',
  },
  {
    title: 'Beautiful interior decor items',
    bg: decor,
    action: 'Shop now',
    path: 'https://shop.avilahinteriors.com',
  },
];

const LandingPage = () => {
  const [currentSlide, setCurrentSlide] = useState(0);

  const imageVariants = {
    enter: (direction) => ({
      opacity: 0.5,
      x: direction > 0 ? 100 : -100,
      scale: 0.8,
      transition: {
        duration: 1,
        ease: easeInOut,
      },
    }),
    center: {
      zIndex: 1,
      opacity: 1,
      x: 0,
      scale: 1,
      transition: {
        duration: 1,
      },
    },
    exit: (direction) => ({
      zIndex: 0,
      opacity: 0,
      x: direction < 0 ? 100 : -100,
      scale: 0.8,
      transition: {
        duration: 1,
        ease: easeIn,
      },
    }),
  };

  const titleVariants = {
    enter: {
      opacity: 0, x: '-100px', scale: 0.9,
    },
    center: {
      opacity: 1,
      x: 0,
      scale: 1,
      transition: {
        duration: 0.8,
      },
    },
    exit: {
      opacity: 0, x: '100px', scale: 0.5,
    },
  };

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentSlide((prev) => (prev === heroBg.length - 1 ? 0 : prev + 1));
    }, 4000);
    return () => clearInterval(interval);
  }, []);

  return (
    <motion.section className={styles.container} style={{ overflow: 'hidden' }}>
      <AnimatePresence custom={1}>
        {heroBg.map((bg, index) => (index === currentSlide ? (
          <motion.img
            key={bg.title}
            src={bg.bg}
            alt="hero"
            className={styles.bgImg}
            custom={1}
            variants={imageVariants}
            initial="enter"
            animate="center"
            exit="exit"
          />
        ) : null))}
      </AnimatePresence>
      <AnimatePresence>
        {heroBg.map((bg, index) => (index === currentSlide ? (
          <motion.h1
            key={bg.title}
            className={styles.title}
            variants={titleVariants}
            initial="enter"
            animate="center"
            exit="exit"
          >
            {bg.title}
          </motion.h1>
        ) : null))}
      </AnimatePresence>
      <Link
        to={heroBg[currentSlide].path}
        className={styles.button}
        whileTap="tap"
      >
        {heroBg[currentSlide].action}
      </Link>
    </motion.section>
  );
};

export default LandingPage;
