import React, { useEffect, useState } from 'react';
import { Link, Outlet } from 'react-router-dom';
import { motion } from 'framer-motion';
import AOS from 'aos';
import 'aos/dist/aos.css';
import 'animate.css';
import { MdMenu, MdClose } from 'react-icons/md';
import { LuSearch } from 'react-icons/lu';
import logo from '../images/logo.svg';
import styles from './Nav.module.scss';

const Nav = () => {
  const [searchTerm, setSearchTerm] = useState('');

  const handleProductSearch = (e) => {
    e.preventDefault();
    if (searchTerm.trim()) {
      const shopUrl = `https://shop.avilahinteriors.com/search?query=${encodeURIComponent(searchTerm)}`;
      window.location.href = shopUrl;
    }
  };

  useEffect(() => {
    AOS.init({
      duration: 500,
      easing: 'ease-in-out',
      once: true,
    });
  }, []);

  const [toggle, setToggle] = useState(false);
  const [search, setSearch] = useState(false);

  // const handleLogout = async (e) => {
  //   e.preventDefault();
  //   await dispatch(userLogout());
  //   navigate('/');
  // };

  const links = [
    {
      id: 1,
      name: 'Home',
      path: '/',
    },
    {
      id: 2,
      name: 'About Us',
      path: '/about-us',
    },
    {
      id: 3,
      name: 'Services',
      path: '/services',
    },
    {
      id: 4,
      name: 'Projects',
      path: '/projects',
    },
    {
      id: 5,
      name: 'Shop',
      path: 'https://shop.avilahinteriors.com',
    },
    {
      id: 6,
      name: 'Contact',
      path: '/contact-us',
    },
  ];

  const variants = {
    show: {
      opacity: 1,
      x: 0,
      transition: {
        x: { stiffness: 1000, velocity: 100 },
      },
    },
    hide: {
      x: -100,
      opacity: 0,
      transition: {
        x: { stiffness: 1000 },
      },
    },
  };

  const handleMenuOpen = () => {
    setToggle(!toggle);
  };

  const handleSearch = () => {
    setSearch(!search);
  };

  const handleMenuClose = () => {
    setToggle(false);
  };

  return (
    <motion.header className={styles.header}>
      <motion.nav
        initial={{ x: -100 }}
        animate={{ x: 0 }}
        transition={{ type: 'spring', stiffness: 120 }}
        className={styles.nav}
      >
        {
          toggle ? (
            <MdClose onClick={handleMenuClose} className={styles.exitMenu} />
          ) : (
            <MdMenu
              onClick={handleMenuOpen}
              className={styles.mobileMenuIcon}
            />
          )
        }
        <Link to="/" className={styles.logo}>
          <img
            src={logo}
            alt="Avilah Interiors"
            className={styles.logoImg}
          />
        </Link>

        <motion.div
          initial={{ x: -100, opacity: 0 }}
          animate={toggle ? 'show' : 'hide'}
          variants={variants}
          transition={{ type: 'spring', duration: 0.3, stiffness: 120 }}
          className={`${styles.navOverlay} ${toggle ? styles.show : styles.hide}`}
          onClick={handleMenuClose}
        >
          <motion.ul
            className={styles.navList}
            onClick={(e) => e.stopPropagation()}
          >
            <Link to="/" className={styles.logo} onClick={handleMenuClose}>
              <img
                src={logo}
                alt="Avilah Interiors"
                className={styles.logoImg}
              />
            </Link>
            {
              links.map((link) => (
                <motion.li
                  key={link.id}
                  className={styles.navItem}
                  whileHover={{ scale: 1.05, originX: 0 }}
                  whileTap={{ scale: 0.95 }}
                >
                  {
                    link.name === 'Shop' ? (
                      <a
                        href={link.path}
                        target="_blank"
                        rel="noreferrer"
                        className={styles.navLink}
                      >
                        {link.name}
                      </a>
                    ) : (
                      <Link
                        to={link.path}
                        className={styles.navLink}
                        onClick={handleMenuClose}
                      >
                        {link.name}
                      </Link>
                    )
                  }
                </motion.li>
              ))
            }
          </motion.ul>
        </motion.div>

        <div className={styles.navBtns}>
          <LuSearch className={styles.icon} onClick={handleSearch} />
          {
            search && (
              <motion.div
                initial={{ x: -100, opacity: 0 }}
                animate={{ x: 0, opacity: 1 }}
                transition={{ duration: 0.3 }}
                className={styles.navSearch}
              >
                <input
                  type="text"
                  placeholder="Search"
                  className={styles.searchInput}
                  value={searchTerm}
                  onChange={(e) => setSearchTerm(e.target.value)}
                />
                <button type="button" className={styles.searchBtn} onClick={handleProductSearch}>
                  Search
                </button>
                <MdClose onClick={handleSearch} className={styles.icon} />
              </motion.div>
            )
          }
          <div className={styles.navUser}>
            <Link to="https://shop.avilahinteriors.com" className={styles.btn}>
              Shop Now
            </Link>
          </div>
        </div>
      </motion.nav>

      <Outlet />
    </motion.header>
  );
};

export default Nav;
