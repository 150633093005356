const stats = [
  {
    id: 1,
    title: 'Unique Designs',
    value: 25,
  },
  {
    id: 2,
    title: 'Happy Clients',
    value: 80,
  },
  {
    id: 3,
    title: 'Consultancy',
    value: 15,
  },
];

export default stats;
