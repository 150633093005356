const reviews = [
  {
    id: 1,
    name: 'Charlene Osadolor',
    text: 'The incredible service that I received from AVILAH INTERIORS is beyond any comparison, Their professionals are creative, have a keen eye for colour and details, and they were amazing at taking my ideas and transforming them into reality. I surely recommend!',
  },
  {
    id: 2,
    name: 'Bamgbade K. Tobi',
    text: 'I am very satisfied with the service provided by AVILAH INTERIORS. Their team values extraordinary service, by showing a very professional concern for the quality of the work they deliver. It was a pleasure working with them in my new office.',
  },
  {
    id: 3,
    name: 'Eberechi C. Maduka',
    text: 'The refurbishing & remodeling services of  AVILAH INTERIORS are just outstanding and very complete. I am constantly amazed by how fast they can put together a blueprint, manage components and work on a tight deadline. Their creativity (especially on budget) is terrific!',
  },
];

export default reviews;
