import accentChair from '../home/accent_chair.svg';
// import bedFrame from '../home/bedframe.svg';
import beanBag from '../home/bean_bag.svg';
import console from '../home/console.svg';
import dresser from '../home/dresser.svg';
import doubleSeater from '../home/double_seater_sofa.svg';
import lShape from '../home/l_shape.svg';
import lShapeSofa from '../home/lshape_sofa.svg';
import leatherSofa from '../home/leather_sofa.svg';
import ottomanBench from '../home/ottoman_bench.svg';
import threeSeater from '../home/three_seater.svg';
import tvSection from '../home/tv_section_furniture.svg';
import horizontalDesk from '../office/horizontal_desk.svg';
import luxuriousDesk from '../office/luxurious_office_desk.svg';
import oneManWorkstation from '../office/one_man_workStation.svg';
import executiveDesk from '../office/executive_office_desk.svg';
import modularScalableOffice from '../office/modular_scalable_office.svg';
import lShapeOfficeCabinet from '../office/l_shape_cabinet.svg';
import receptionDesk from '../office/reception_desk.svg';
import conferenceRoomFurniture from '../office/conference_room_furn.svg';
import boothSeat from '../office/booth_seat.svg';
import boothSeat2 from '../office/booth_seater.svg';

const home = [
  {
    id: 1,
    img: doubleSeater,
    desc: 'Double seater sofa',
  },
  {
    id: 3,
    img: threeSeater,
    desc: 'Three seater sofa',
  },
  {
    id: 4,
    img: beanBag,
    desc: 'Bean bag',
  },
  {
    id: 5,
    img: lShape,
    desc: 'L-shape',
  },
  {
    id: 6,
    img: tvSection,
    desc: 'TV section furniture',
  },
  {
    id: 7,
    img: ottomanBench,
    desc: 'Ottoman bench',
  },
  {
    id: 8,
    img: accentChair,
    desc: 'Accent chair',
  },
  {
    id: 9,
    img: lShapeSofa,
    desc: 'L-shape sofa',
  },
  {
    id: 10,
    img: leatherSofa,
    desc: 'Leather sofa',
  },
  {
    id: 11,
    img: dresser,
    desc: 'Dresser',
  },
  {
    id: 12,
    img: console,
    desc: 'Console',
  },
];

const office = [
  {
    id: 1,
    img: horizontalDesk,
    desc: 'Horizontal T desk',
  },
  {
    id: 2,
    img: luxuriousDesk,
    desc: 'Luxurious office desk',
  },
  {
    id: 3,
    img: oneManWorkstation,
    desc: 'One-man workstation',
  },
  {
    id: 4,
    img: executiveDesk,
    desc: 'Executive office desk',
  },
  {
    id: 5,
    img: modularScalableOffice,
    desc: 'Modular scalable office',
  },
  {
    id: 6,
    img: lShapeOfficeCabinet,
    desc: 'L-shape office cabinet',
  },
  {
    id: 7,
    img: receptionDesk,
    desc: 'Reception desk',
  },
  {
    id: 8,
    img: conferenceRoomFurniture,
    desc: 'Conference room furniture',
  },
  {
    id: 9,
    img: boothSeat,
    desc: 'Booth seat',
  },
  {
    id: 10,
    img: boothSeat2,
    desc: 'Booth seat',
  },
];

export { home, office };
