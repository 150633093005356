/* eslint-disable linebreak-style */
/* eslint-disable no-nested-ternary */
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable import/no-extraneous-dependencies */
import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { motion } from 'framer-motion';
import { useFormik } from 'formik';
import { useForm } from '@formspree/react';
import { toast } from 'react-toastify';
import { PhoneInput } from 'react-international-phone';
import Select from 'react-select';
import 'react-international-phone/style.css';
import styles from './Interior.module.scss';

const validate = (values) => {
  const errors = {};

  if (!values.name) {
    errors.name = 'Name is required';
  }

  if (!values.email) {
    errors.email = 'Email is required';
  } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.email)) {
    errors.email = 'Invalid email address';
  }

  if (!values.preferredContact) {
    errors.preferredContact = 'How would you like to be contacted?';
  }

  if (!values.phone) {
    errors.phone = 'Must be valid number e.g. +2348012345678';
  } else if (!/^\+234(\s?\d){10}$/.test(values.phone)) {
    errors.phone = 'Invalid phone number';
  }

  return errors;
};

const options = [
  { value: 'commercial', label: 'Commercial' },
  { value: 'residential', label: 'Residential' },
];

const options2 = [
  { value: '4 bedroom', label: '4 bedroom' },
  { value: '3 bedroom', label: '3 bedroom' },
  { value: '2 bedroom', label: '2 bedroom' },
  { value: 'Open floor plan office', label: 'Open floor plan office' },
  { value: 'Other', label: 'Other' },
];

const Interior = () => {
  const navigate = useNavigate();
  const [state, handleSubmit] = useForm('xvgonzdn');

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const formik = useFormik({
    initialValues: {
      name: '',
      phone: '',
      email: '',
      preferredContact: '',
      preferredStyle: '',
      preferredColor: '',
      requirements: '',
      projectType: '',
      projectLocation: '',
      projectSize: '',
      projectUse: '',
      timeline: '',
      info: '',
    },
    validate,
    onSubmit: async (values) => {
      if (!values) {
        toast.error('Please fill in the required fields');
      } else {
        await handleSubmit(values);
        navigate('/thank-you');
      }
    },
  });

  return (
    <motion.section
      className={styles.interior}
      initial={{ width: 0 }}
      animate={{ width: '100%' }}
      exit={{ x: window.innerWidth, transition: { duration: 0.2 } }}
      style={{ overflow: 'hidden' }}
    >
      <article className={styles.intro}>
        <p className={styles.formPara}>
          At Avilah interiors, we specialize in delivering exceptional interior
          design and custom furniture solutions. Our experts are here to bring
          your vision to life with personalized designs and professional craftsmanship.
          Let’s create beautiful spaces together!
        </p>
      </article>
      <form className={styles.form} onSubmit={formik.handleSubmit}>
        <div className={styles.subGroup}>
          <div className={styles.formGroup}>
            <h2 className={styles.formHead}>Contact Details</h2>
            <input
              type="text"
              className={styles.formField}
              name="name"
              placeholder="Name"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.name}
            />
            {formik.touched.name && formik.errors.name ? (
              <small className={styles.error}>
                <span style={{ color: 'red' }}>* </span>
                {formik.errors.name}
              </small>
            ) : null}
            <PhoneInput
              placeholder="Phone number"
              value={formik.values.phone}
              onChange={(phone) => formik.setFieldValue('phone', phone)}
              onBlur={() => formik.setFieldTouched('phone', true)}
              defaultCountry="ng"
              international
            />
            {formik.touched.phone && formik.errors.phone ? (
              <small className={styles.error}>
                <span style={{ color: 'red' }}>* </span>
                {formik.errors.phone}
              </small>
            ) : null}
            <input
              type="email"
              className={styles.formField}
              name="email"
              placeholder="Email address"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.email}
            />
            {formik.touched.email && formik.errors.email ? (
              <small className={styles.error}>
                <span style={{ color: 'red' }}>* </span>
                {formik.errors.email}
              </small>
            ) : null}
            <input
              type="text"
              name="preferredContact"
              className={styles.formField}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              placeholder="Preferred method of contact"
              value={formik.values.preferredContact}
            />
            {formik.touched.preferredContact && formik.errors.preferredContact ? (
              <small className={styles.error}>
                <span style={{ color: 'red' }}>* </span>
                {formik.errors.preferredContact}
              </small>
            ) : null}
          </div>
          <div className={styles.formGroup}>
            <h2 className={styles.formHead}>Design Preferences</h2>
            <input
              type="text"
              name="preferredStyle"
              className={styles.formField}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              placeholder="Style"
              value={formik.values.preferredStyle}
            />

            <input
              type="text"
              className={styles.formField}
              name="preferredColor"
              placeholder="Color of choice"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.preferredColor}
            />
            <input
              name="requirements"
              className={styles.formField}
              onChange={formik.handleChange}
              placeholder="Any specific requirements or desires"
              onBlur={formik.handleBlur}
              value={formik.values.requirements}
            />
          </div>
        </div>
        <div className={styles.subGroup}>
          <div className={styles.formGroup}>
            <h2 className={styles.formHead}>Project Details</h2>
            <Select
              placeholder="Type of project"
              value={formik.values.projectType}
              onChange={(projectType) => formik.setFieldValue('projectType', projectType)}
              onBlur={() => formik.setFieldTouched('projectType', true)}
              options={options}
              theme={(theme) => ({
                ...theme,
                borderRadius: 0,
                border: 'none',
                backgroundColor: '#f2f2f2',
                colors: {
                  ...theme.colors,
                  primary25: 'rgb(234, 207, 252)',
                  primary: 'rgb(212, 162, 246)',
                },
              })}
            />
            <input
              type="text"
              className={styles.formField}
              name="projectLocation"
              placeholder="Location"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.projectLocation}
            />
            <Select
              placeholder="Size of project"
              value={formik.values.projectSize}
              onChange={(projectSize) => formik.setFieldValue('projectSize', projectSize)}
              onBlur={() => formik.setFieldTouched('projectSize', true)}
              options={options2}
              theme={(theme) => ({
                ...theme,
                borderRadius: 0,
                border: 'none',
                backgroundColor: '#f2f2f2',
                colors: {
                  ...theme.colors,
                  primary25: 'rgb(234, 207, 252)',
                  primary: 'rgb(212, 162, 246)',
                },
              })}
              styles={{
                backgroundColor: '#979797 !important',
                height: 52,
              }}
            />
            <input
              type="text"
              name="projectUse"
              className={styles.formField}
              onChange={formik.handleChange}
              placeholder="Intended use of space"
              onBlur={formik.handleBlur}
              value={formik.values.projectUse}
            />
          </div>
          <div className={styles.formGroup}>
            <div className={styles.formGroup}>
              <h2 className={styles.formHead}>Budget</h2>
              <input
                type="text"
                className={styles.formField}
                name="budget"
                placeholder="Budget for project (Please specify currency)"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.budget}
              />
            </div>
            <div className={styles.formGroup}>
              <h2 className={styles.formHead}>Timeline</h2>
              <input
                type="text"
                className={styles.formField}
                name="timeline"
                placeholder="Expected project start and completion date"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.timeline}
              />
            </div>
            <div className={styles.formGroup}>
              <h2 className={styles.formHead}>Additional Information</h2>
              <textarea
                name="info"
                className={styles.formField}
                onChange={formik.handleChange}
                placeholder="Any other information you would like"
                onBlur={formik.handleBlur}
                value={formik.values.info}
              />
              <button type="submit" className={styles.btnB} disabled={!formik.isValid || state.submitting}>Submit</button>
            </div>
          </div>
        </div>
        <button type="submit" className={styles.btn} disabled={!formik.isValid || state.submitting}>Submit</button>
      </form>
    </motion.section>
  );
};

export default Interior;
