import React from 'react';
import { motion } from 'framer-motion';
import styles from './Why.module.scss';
import why from '../../data/why';

const Why = () => (
  <motion.section className={styles.container} style={{ overflow: 'hidden' }}>
    <article className={styles.header}>
      <h2 className={styles.title}>Why Choose Us</h2>
      <div className={styles.underline} />
    </article>

    <article className={styles.content}>
      <article className={styles.textContent}>
        <p className={styles.text}>Here’s why you’ll love working with us</p>
      </article>
      <article className={styles.perksDiv}>
        {why.map((perk) => (
          <motion.article
            initial={{ opacity: 0, y: 50 }}
            whileInView={{ opacity: 1, y: 0, transition: { duration: 0.5 } }}
            className={styles.perk}
            key={perk.id}
          >
            {perk.icon}
            <div className={styles.div}>
              <h5 className={styles.heading}>{perk.title}</h5>
              <p className={styles.text}>{perk.text}</p>
            </div>
          </motion.article>
        ))}
      </article>
    </article>
  </motion.section>
);

export default Why;
