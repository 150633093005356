/* eslint-disable max-len */
/* eslint-disable arrow-body-style */
import React, { useEffect } from 'react';
import { motion } from 'framer-motion';
import styles from './About.module.scss';
import aboutImg from '../../images/ceo.png';
import core from '../../images/core.svg';
import process from '../../data/process';
import Testimonial from '../../components/Testimonial/Testimonial';

const About = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <motion.section
      className={styles.container}
      initial={{ width: 0 }}
      animate={{ width: '100%' }}
      exit={{ x: window.innerWidth, transition: { duration: 0.2 } }}
    >
      <article className={styles.header}>
        <h2 className={styles.title}>About us</h2>
      </article>
      <article className={styles.content}>
        <motion.p
          initial={{ opacity: 0, y: 150 }}
          whileInView={{ opacity: 1, y: 0 }}
          transition={{ delay: 0.5, duration: 0.5 }}
          viewport={{ once: true }}
          className={styles.text}
        >
          Welcome to Avilah Interiors, a quality-driven interior design and furniture-making
          company based in Lagos, Nigeria. Established in 2018 with a team of skilled designers,
          architects, and craftsmen, we specialise in creating functional and aesthetically
          pleasing bespoke designs for homes and offices. Avilah Interiors provides exceptional
          interior design services, bespoke furniture-making and sells interior décor items for
          residential and commercial spaces.
        </motion.p>
        <motion.p
          initial={{ opacity: 0, y: 150 }}
          whileInView={{ opacity: 1, y: 0 }}
          transition={{ delay: 0.5, duration: 0.5 }}
          viewport={{ once: true }}
          className={`${styles.text} ${styles.lastText}`}
        >
          Our commitment to quality is unwavering, and we
          aim to deliver interior design solutions and furniture that consistently meet high
          quality, reliability, and functionality standards, giving our clients peace of mind
          while providing comfort to their dwellings. From concept development to project
          management and delivery, we offer end-to-end services to bring your interior vision
          to life. Our attention to detail, commitment to quality, excellent customer service
          and passion for innovation set us apart in the industry. Whether you want to revamp
          your living space or create a productive work environment, Avilah Interiors is here
          to exceed your expectations.
        </motion.p>
        <motion.article
          className={styles.textContent}
        >
          <motion.img
            initial={{ opacity: 0.3, y: 50, borderRadius: '50%' }}
            whileInView={{ opacity: 1, y: 0, borderRadius: '0' }}
            transition={{ delay: 0.3, duration: 0.3 }}
            viewport={{ once: true }}
            src={aboutImg}
            alt="I’m Oluwabusolami"
            className={styles.aboutImg}
          />
          <article className={styles.textDiv}>
            <motion.h2
              initial={{ opacity: 0.3, y: 100 }}
              whileInView={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.3 }}
              viewport={{ once: true }}
              className={styles.subtitle}
            >
              Hi, I’m Oluwabusolami
            </motion.h2>
            <motion.p
              initial={{ opacity: 0.3, y: 100 }}
              whileInView={{ opacity: 1, y: 0 }}
              transition={{ duration: 1 }}
              viewport={{ once: true }}
              className={styles.text}
            >
              The visionary creative lead of Avilah Interiors, a name synonymous with increase,
              opulence, elegance, and unparalleled design excellence.
              <span className={styles.bold}> Busola is a trained and certified interior designer </span>
              whose journey into the design world began
              with a passion for creating spaces that reflect beauty and resonate with
              functionality. Her practical knowledge and experience are a testament to her
              commitment to her craft, having honed her skills by apprenticing under a
              seasoned interior designer after her academic certification in interior
              design before venturing out to establish Avilah Interiors in 2018.
            </motion.p>
            <motion.p
              initial={{ opacity: 0.3, y: 100 }}
              whileInView={{ opacity: 1, y: 0 }}
              transition={{ duration: 1 }}
              viewport={{ once: true }}
              className={`${styles.text} ${styles.lastText}`}
            >
              As the driving force behind Avilah Interiors,
              <span className={styles.bold}> Busola leads a talented team of interior designers, architects, and craftsmen </span>
              with a blend of her expertise and unwavering dedication to excellence. Her leadership skills are marked by a balance
              of creativity and precision, resulting in transformative design solutions that leave a lasting impression.
              <br />
              Dedicated, enthusiastic, and driven by the pursuit of excellence, Busola approaches each project with a keen
              eye for detail and a passion for creating spaces that inspire and uplift. Her love for people and beautiful spaces
              is evident in every design she brings to life, as she believes that everyone deserves to feel good and find solace
              in the shelter of a well-designed space.
            </motion.p>
            <motion.p
              className={`${styles.text} ${styles.lastText}`}
              initial={{ opacity: 0.3, y: 150 }}
              whileInView={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.5 }}
              viewport={{ once: true }}
            >
              Guided by a philosophy that blends innovation with a deep understanding of
              client needs,
              <span className={styles.bold}> Busola is known for her ability to seamlessly combine aesthetics with functionality, creating interiors that dazzle the eye and elevate the human experience. </span>
              With a keen sense of style, a penchant for creativity, and a commitment to
              delivering beyond expectations, Busola Ilesanmi embodies the essence of
              an authentic interior design maestro.
            </motion.p>
          </article>
        </motion.article>
      </article>
      <article className={styles.spliter}>
        <motion.p
          initial={{ opacity: 0.3, y: 100 }}
          whileInView={{ opacity: 1, y: 0 }}
          transition={{ duration: 1 }}
          viewport={{ once: true }}
          className={styles.text}
        >
          As the driving force behind Avilah Interiors,
          <span className={styles.bold}> Busola leads a talented team of interior designers, architects, and craftsmen </span>
          with a blend of her expertise and unwavering dedication to excellence. Her leadership skills are marked by a balance
          of creativity and precision, resulting in transformative design solutions that leave a lasting impression.
          <br />
          Dedicated, enthusiastic, and driven by the pursuit of excellence, Busola approaches each project with a keen
          eye for detail and a passion for creating spaces that inspire and uplift. Her love for people and beautiful spaces
          is evident in every design she brings to life, as she believes that everyone deserves to feel good and find solace
          in the shelter of a well-designed space.
        </motion.p>
        <motion.p
          className={styles.text}
          initial={{ opacity: 0.3, y: 150 }}
          whileInView={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.5 }}
          viewport={{ once: true }}
        >
          Guided by a philosophy that blends innovation with a deep understanding of
          client needs,
          <span className={styles.bold}> Busola is known for her ability to seamlessly combine aesthetics with functionality, creating interiors that dazzle the eye and elevate the human experience. </span>
          With a keen sense of style, a penchant for creativity, and a commitment to
          delivering beyond expectations, Busola Ilesanmi embodies the essence of
          an authentic interior design maestro.
        </motion.p>
      </article>
      <section className={styles.missionContainer}>
        <article className={styles.content}>
          <motion.article
            initial={{ opacity: 0.3, y: 150 }}
            whileInView={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.5 }}
            viewport={{ once: true }}
            className={styles.textContent}
          >
            <h3 className={styles.subtitle}>Our Mission</h3>
            <p className={styles.text}>
              Avilah Interiors is dedicated to exceeding client expectations by
              delivering quality interior design services, bespoke furniture,
              and décor items. We understand that each client is unique, and we
              tailor our solutions to suit individual and corporate organisation
              tastes and preferences, ensuring that every client feels valued
              and understood.
            </p>
            <h3 className={styles.subtitle}>Our Vision</h3>
            <p className={styles.text}>
              To be the most sought-after brand for innovative and exquisite
              interior design solutions and quality furniture in Africa.
            </p>
          </motion.article>
          <img src={core} className={styles.aboutImg} alt="Core Values" />
        </article>

        <article className={styles.content}>
          <article className={styles.textContent}>
            <h3 className={styles.subtitle}>Our Process</h3>
            <ul className={styles.list}>
              {
                process.map((data) => {
                  return (
                    <motion.li
                      initial={{ opacity: 0, y: 50 }}
                      whileInView={{ opacity: 1, y: 0 }}
                      transition={{
                        delay: 0.3, duration: 0.3, type: 'spring', stiffness: 150,
                      }}
                      viewport={{ once: true }}
                      key={data.id}
                      className={styles.listItem}
                    >
                      <img src={data.badge} alt="" className={styles.badge} />
                      <div className={styles.listText}>
                        <h4 className={styles.heading}>{data.title}</h4>
                        <p className={styles.text}>{data.text}</p>
                      </div>
                    </motion.li>
                  );
                })
              }
            </ul>
          </article>
        </article>
      </section>
      <Testimonial />
    </motion.section>
  );
};

export default About;
