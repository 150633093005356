import proTl from '../images/pro_tl.png';

const projects = [
  {
    id: 1,
    title: 'Parkview',
    name: 'Project PV1',
    details: {
      completion: 'Q3, 2023',
      location: 'Ikoyi, Lagos',
      client: 'Individual',
      category: 'Residential',
    },
    intro: 'We redesigned a three-bedroom apartment in Ikoyi, Lagos. From the living room to the dining area, lobby, guest bedroom, main bedroom and the last bedroom were converted to a home study to suit the client’s preferences and design needs.',
    description: 'We redesigned a three-bedroom apartment in Ikoyi, Lagos. From the living room to the dining area, lobby, guest bedroom, main bedroom and the last bedroom was converted to a home study to suit the client’s preferences and design needs. 80% of the furniture used to adore this home was proudly produced by Avilah Interiors, while the remaining 20% (living sofa & dining table & chairs) was imported. The living room rug was specially customised and produced for the client. No quality and aesthetic stone was left unturned.',
    images: [
      'https://res.cloudinary.com/dn1ko8lbn/image/upload/v1720279493/avilah/pv1/pro_pv1_qrlvmg.png',
      'https://res.cloudinary.com/dn1ko8lbn/image/upload/v1720279499/avilah/pv1/pv1a_f1hntj.png',
      'https://res.cloudinary.com/dn1ko8lbn/image/upload/v1720279497/avilah/pv1/pv1j_nyxs51.png',
      'https://res.cloudinary.com/dn1ko8lbn/image/upload/v1720279492/avilah/pv1/pv1e_w6eggz.png',
      'https://res.cloudinary.com/dn1ko8lbn/image/upload/v1720279492/avilah/pv1/pv1c_ozosdu.png',
      'https://res.cloudinary.com/dn1ko8lbn/image/upload/v1720279491/avilah/pv1/pv1h_ojxgdl.png',
      'https://res.cloudinary.com/dn1ko8lbn/image/upload/v1720279477/avilah/pv1/pv1f_sxw3da.png',
      'https://res.cloudinary.com/dn1ko8lbn/image/upload/v1720279477/avilah/pv1/pv1d_rrsmoq.png',
      'https://res.cloudinary.com/dn1ko8lbn/image/upload/v1720279476/avilah/pv1/pv1i_ccpvmm.png',
      'https://res.cloudinary.com/dn1ko8lbn/image/upload/v1720279473/avilah/pv1/pv1b_tgiwah.png',
      'https://res.cloudinary.com/dn1ko8lbn/image/upload/v1720279471/avilah/pv1/pv1g_ahnedx.png',
    ],
  },
  {
    id: 2,
    title: 'IB Akala',
    name: 'Project IB Akala',
    details: {
      completion: 'Q1, 2022',
      location: 'Ibadan, Oyo',
      client: 'Individual',
      category: 'Residential',
    },
    intro: 'A duplex apartment in Akala Ibadan, Oyo State, where Avilah Interiors was contacted to redesign the ante-room, main bedroom, main bedroom rest room and closet/dressing area in the main bedroom. Again, we left no quality or aesthetic stone unturned.',
    description: '',
    images: [
      'https://res.cloudinary.com/dn1ko8lbn/image/upload/v1720279478/avilah/ib/pro_ib_ubgcnd.png',
      'https://res.cloudinary.com/dn1ko8lbn/image/upload/v1720279482/avilah/ib/iba_kjpbfo.png',
      'https://res.cloudinary.com/dn1ko8lbn/image/upload/v1720279488/avilah/ib/ibb_yxe0it.png',
      'https://res.cloudinary.com/dn1ko8lbn/image/upload/v1720279495/avilah/ib/ibf_on0oxr.png',
      'https://res.cloudinary.com/dn1ko8lbn/image/upload/v1720279486/avilah/ib/ibe_ols0cc.png',
      'https://res.cloudinary.com/dn1ko8lbn/image/upload/v1720279477/avilah/ib/ibd_rnpvba.png',
      'https://res.cloudinary.com/dn1ko8lbn/image/upload/v1720279477/avilah/ib/ibc_k9drrl.png',
    ],
  },
  {
    id: 3,
    title: 'POA ADIT',
    name: 'Project POA',
    details: {
      completion: 'Q2, 2022',
      location: 'VI, Lagos',
      client: 'Private Organization',
      category: 'Commercial',
    },
    intro: 'An office space designed for a construction company in Victoria Island, Lagos. The staff strength is a mixed generation where we had to create a fun and relaxing work environment for the Gen Z, Millenials, and Baby Boomers, to mention a few.',
    description: 'An office space designed for a construction company in Victoria Island, Lagos. The staff strength is a mixed generation where we had to create a fun and relaxing work environment for the Gen Z, Millenials, and Baby Boomers, to mention a few. The modern office includes a cute waiting corner at the reception with some artificial flower walls with the write-up “Relax and be productive”. The reception area was breathtakingly beautiful to make visitors feel welcomed and comfortable; the conference room was themed in black and white with a 14-man seating arrangement, two open offices were well styled and inspiring with some motivational quotes on the wall, the MD’s office wasn’t left out and our favourite part of the space – lounge a.k.a. the pause room where staff can have a break, eat, watch TV, play games among other fun things. No quality or aesthetic stone was left unturned.',
    images: [
      'https://res.cloudinary.com/dn1ko8lbn/image/upload/v1720279487/avilah/poa/poaa_uq0syz.png',
      'https://res.cloudinary.com/dn1ko8lbn/image/upload/v1720279483/avilah/poa/poab_krvh2w.png',
      'https://res.cloudinary.com/dn1ko8lbn/image/upload/v1720279482/avilah/poa/poac_mxiwzw.png',
      'https://res.cloudinary.com/dn1ko8lbn/image/upload/v1720279482/avilah/poa/poaf_b4u2ul.png',
      'https://res.cloudinary.com/dn1ko8lbn/image/upload/v1722436157/avilah/poa/recep_ycovsb.png',
      'https://res.cloudinary.com/dn1ko8lbn/image/upload/v1720279477/avilah/poa/poad_eenlri.png',
      'https://res.cloudinary.com/dn1ko8lbn/image/upload/v1720279474/avilah/poa/pro_poa_ak5kth.png',
    ],
  },
  {
    id: 4,
    title: 'Stonelanding',
    name: 'Project ST',
    details: {
      completion: 'Q2, 2023',
      location: 'Oniru-Lekki, Lagos',
      client: 'Individual',
      category: 'Residential',
    },
    intro: 'A three-bedroom residential space in Oniru-lekki Lagos. Avilah Interiors designed and executed the interiors of the space, including furniture making and purchase.',
    description: 'A three-bedroom residential space in Oniru-lekki Lagos. Avilah Interiors designed and executed the interiors of the space, including furniture making and purchase. One of the rooms was converted to a dual-purpose space for a home office and indoor gym, while the other rooms were designed as the main bedroom and guest bedroom. The living room with a minibar by a corner was not left out of our incredible touch.',
    images: [
      'https://res.cloudinary.com/dn1ko8lbn/image/upload/v1720279492/avilah/st/sta_ihnt8y.png',
      'https://res.cloudinary.com/dn1ko8lbn/image/upload/v1720279498/avilah/st/pro_st_hbigwr.png',
      'https://res.cloudinary.com/dn1ko8lbn/image/upload/v1720279495/avilah/st/stc_qokyjx.png',
      'https://res.cloudinary.com/dn1ko8lbn/image/upload/v1720279490/avilah/st/std_fbsehq.png',
      'https://res.cloudinary.com/dn1ko8lbn/image/upload/v1720279488/avilah/st/stb_hwmqjt.png',
      'https://res.cloudinary.com/dn1ko8lbn/image/upload/v1723365907/avilah/st/ste_mdwrvh.png',
      'https://res.cloudinary.com/dn1ko8lbn/image/upload/v1723365907/avilah/st/stf_ufkuhx.png',
    ],
  },
  {
    id: 5,
    title: 'Skystone',
    name: 'Project SKT',
    details: {
      completion: 'Q1, 2023',
      location: 'VI, Lagos',
      client: 'Private Organization',
      category: 'Commercial',
    },
    intro: 'An old building structure was remodeled into an office space in Victoria Island, Lagos. Avilah Interiors designed the interior of the office space, produced all furniture, and installed other furnishings and styling.',
    description: 'An old building structure was remodelled into an office space in Victoria Island, Lagos. Avilah Interiors designed the interior of the office space, produced all furniture, and installed other furnishings and styling. Spaces designed include the reception, staff open offices, two lounges, a conference room, and two managers’ offices. Among all the beautiful pieces put together for this project, our highlight is the eclipse-shaped reception desk produced. Again, no quality and aesthetic stone was left unturned.',
    images: [
      'https://res.cloudinary.com/dn1ko8lbn/image/upload/v1720279492/avilah/st/pro_skt_a3tvm9.png',
      'https://res.cloudinary.com/dn1ko8lbn/image/upload/v1720279484/avilah/skt/skta_my5nj2.png',
      'https://res.cloudinary.com/dn1ko8lbn/image/upload/v1723366016/avilah/skt/skt_s1_hifjxu.png',
      'https://res.cloudinary.com/dn1ko8lbn/image/upload/v1720279491/avilah/skt/sktd_vetilr.png',
      'https://res.cloudinary.com/dn1ko8lbn/image/upload/v1720279481/avilah/skt/sktc_ocdxhq.png',
      'https://res.cloudinary.com/dn1ko8lbn/image/upload/v1720279481/avilah/skt/skte_k1n3lp.png',
      'https://res.cloudinary.com/dn1ko8lbn/image/upload/v1723366016/avilah/skt/skt_s2_jfmmm7.png',
    ],
  },
  {
    id: 6,
    title: 'Tulcan',
    name: 'Project TL',
    details: {
      completion: 'Q1, 2023',
      location: 'VI, Lagos',
      client: 'Individual',
      category: 'Commercial',
    },
    intro: 'Modern office space interior design and workstation production for an oil and gas company in Victoria Island, Lagos.',
    description: 'Modern office space interior design and workstation production for an oil and gas company in Victoria Island, Lagos.',
    images: [
      proTl,
      'https://res.cloudinary.com/dn1ko8lbn/image/upload/v1720279482/avilah/tl/tla_w9uiuv.png',
      'https://res.cloudinary.com/dn1ko8lbn/image/upload/v1720279488/avilah/tl/tlc_zg7eid.png',
      'https://res.cloudinary.com/dn1ko8lbn/image/upload/v1720279485/avilah/tl/tlb_umra7r.png',
      'https://res.cloudinary.com/dn1ko8lbn/image/upload/v1720279476/avilah/tl/tld_hhwzbg.png',
      'https://res.cloudinary.com/dn1ko8lbn/image/upload/v1720279485/avilah/tl/tle_rhacm1.png',
      'https://res.cloudinary.com/dn1ko8lbn/image/upload/v1720279482/avilah/tl/tlf_wy85n3.png',
    ],
  },
  {
    id: 7,
    title: 'HD Apartment',
    name: 'Project HD',
    details: {
      completion: 'Q1, 2021',
      location: 'Oniru Lekki, Lagos',
      client: 'Private Organization',
      category: 'Commercial',
    },
    intro: 'On this project, we used a complementary color scheme while preserving color balance with soft white and grey as wall paint and nude as the wood color for TV cabinet and laminated floor.',
    description: 'On this project, we used a complementary color scheme while preserving color balance with soft white and grey as wall paint and nude as the wood color for TV cabinet and laminated floor. The custard yellow sofa and blue throws complements perfectly well. We created a vivid and energizing effect in the living room for the family. “When people have great rooms, they put their television in there, their kids play in there, and they’ll even eat in there” observes Katie Leavy that’s the case with the “HD Apartment”, a great living room designed by Avilah Interiors.',
    images: [
      'https://res.cloudinary.com/dn1ko8lbn/image/upload/v1720279441/avilah/hd/pro_hd_sffldt.png',
      'https://res.cloudinary.com/dn1ko8lbn/image/upload/v1720279442/avilah/hd/hda_ikvgyw.png',
      'https://res.cloudinary.com/dn1ko8lbn/image/upload/v1720279442/avilah/hd/hdb_nqoshd.png',
      'https://res.cloudinary.com/dn1ko8lbn/image/upload/v1720279442/avilah/hd/hdd_fgfkdp.png',
      'https://res.cloudinary.com/dn1ko8lbn/image/upload/v1720279444/avilah/hd/hdc_h9hqgb.png',
    ],
  },
  {
    id: 8,
    title: 'Major Gate',
    name: 'Project MG',
    details: {
      completion: 'Q4, 2018',
      location: 'Magodo, Lagos',
      client: 'Individual',
      category: 'Residential',
    },
    intro: 'A five-bedroom duplex designed for a family in the mainland area of Lagos. Avilah Interiors designed and executed the Living room, which included the anteroom, understairs, dining area and a bar.',
    description: "A five-bedroom duplex designed for a family in the mainland area of Lagos. Avilah Interiors designed and executed the Living room, which included the anteroom, understairs, dining area and a bar. The family lounge, kids’ rooms, guest rooms, the master bedroom and a walk-in closet. We have a good sentiment to this project as it was our first full-service project. We remain grateful for the client's trust in the brand and for becoming our returning client over the years.",
    images: [
      'https://res.cloudinary.com/dn1ko8lbn/image/upload/v1720279471/avilah/mg/pro_mg_udcypz.png',
      'https://res.cloudinary.com/dn1ko8lbn/image/upload/v1720279472/avilah/mg/mga_edyod3.png',
      'https://res.cloudinary.com/dn1ko8lbn/image/upload/v1720279471/avilah/mg/mgb_n6uwin.png',
      'https://res.cloudinary.com/dn1ko8lbn/image/upload/v1720279472/avilah/mg/mgc_xvghl9.png',
      'https://res.cloudinary.com/dn1ko8lbn/image/upload/v1720279471/avilah/mg/mgd_budopi.png',
    ],
  },
];

export default projects;
