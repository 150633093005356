import serviceOne from '../images/serviceOne.png';
import serviceTwo from '../images/serviceTwo.png';
import serviceThree from '../images/service_three.png';

const services = [
  {
    id: 1,
    title: 'Interior Design Solution',
    icon: serviceOne,
    image: [
      'https://res.cloudinary.com/dn1ko8lbn/image/upload/v1724752729/avilah/st/interior_t6aujk.png',
      'https://res.cloudinary.com/dn1ko8lbn/image/upload/v1724695145/avilah/st/SAVE_20240826_142414_1inter_vxflvb.png',
      'https://res.cloudinary.com/dn1ko8lbn/image/upload/v1721589052/avilah/furniture/three_sgjrw8.png',
      'https://res.cloudinary.com/dn1ko8lbn/image/upload/v1721589052/avilah/furniture/four_qs9n8n.png',
    ],
    details: 'Step into a world where quality meets functionality, where spaces are transformed into homes and productive work environments, and where everyone feels welcomed, comfortable, and valued. Welcome to our realm of interior design excellence! Whether you are looking to infuse your home with warmth and coziness or breathe new life into your office environment, our talented team of designers is here to make your vision a reality.',
    path: '/services',
    action: '/consultation/interior-design',
    button: 'Book a consultation',
  },
  {
    id: 2,
    title: 'Furniture-Making',
    icon: serviceTwo,
    image: [
      'https://res.cloudinary.com/dn1ko8lbn/image/upload/v1721222211/avilah/furn_four_haeb5y.png',
      'https://res.cloudinary.com/dn1ko8lbn/image/upload/v1721222529/avilah/furn_one_zqn4cn.png',
      'https://res.cloudinary.com/dn1ko8lbn/image/upload/v1721222700/avilah/furn_two_bkisgy.png',
      'https://res.cloudinary.com/dn1ko8lbn/image/upload/v1721222208/avilah/furn_three_nmgdez.png',
    ],
    details: "Our services go beyond creating beautiful spaces—we are also master craftsmen in making furniture that perfectly complements your space's aesthetics. From elegant pieces for your living room to functional workstations for your office, we take pride in handcrafting quality furniture as unique as you are.",
    path: 'https://shop.avilahinteriors.com/bespoke-furniture',
    action: '/consultation/furniture-making',
    button: 'Order furniture',
  },
  {
    id: 3,
    title: ' Sale of Interior Décor Items',
    icon: serviceThree,
    image: [
      'https://res.cloudinary.com/dn1ko8lbn/image/upload/v1721222514/avilah/decor_one_w6g6pc.png',
      'https://res.cloudinary.com/dn1ko8lbn/image/upload/v1721222213/avilah/decor_two_x6vtnk.png',
      'https://res.cloudinary.com/dn1ko8lbn/image/upload/v1721222209/avilah/decor_three_wgrgaw.png',
      'https://res.cloudinary.com/dn1ko8lbn/image/upload/v1721222209/avilah/decor_four_pul0ml.png',
    ],
    details: 'To add the perfect finishing touches to your newly designed space, we offer a carefully curated selection of interior décor items that elevate your home and office styling game to a new level. Whether you’re looking for that statement piece to tie the room together or simply seeking inspiration, our collection of décor items will surely delight and inspire.',
    path: 'https://shop.avilahinteriors.com/interior-decor-items',
    action: 'https://shop.avilahinteriors.com/interior-decor-items',
    button: 'Shop now',
  },
];

export default services;
