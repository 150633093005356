import { MdOutlineZoomIn, MdOutlineLightbulb } from 'react-icons/md';
import { PiShieldCheck, PiSmileyWink } from 'react-icons/pi';
import styles from '../components/Why/Why.module.scss';

const why = [
  {
    id: 1,
    icon: <MdOutlineZoomIn className={styles.icon} />,
    title: 'Attention to Details',
    text: 'We meticulously focus on every aspects of design to ensure accurate, error-free, excellent and quality work.',
  },
  {
    id: 2,
    icon: <PiShieldCheck className={styles.icon} />,
    title: 'Commitment to Quality',
    text: 'Our commitment to quality drives us to consistently deliver work that meets the highest standards of excellence.',
  },
  {
    id: 3,
    icon: <PiSmileyWink className={styles.icon} />,
    title: 'Excellent Customer Service',
    text: 'We go extra mile to ensure every customer feels valued and satisfied. By actively listening and promptly addressing concerns.',
  },
  {
    id: 4,
    icon: <MdOutlineLightbulb className={styles.icon} />,
    title: 'Passion for Innovation',
    text: 'Our passion for innovation drives us to constantly explore new ideas and push the boundaries of possibilities.',
  },
];

export default why;
