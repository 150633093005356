/* eslint-disable linebreak-style */
/* eslint-disable arrow-body-style */
/* eslint-disable max-len */
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { FaAngleDoubleRight, FaAngleDoubleLeft } from 'react-icons/fa';
import { motion, AnimatePresence } from 'framer-motion';
import projects from '../../data/projects';
import styles from './ProjectFeature.module.scss';

const ProjectFeature = () => {
  const { projectId } = useParams();
  const [selectedProject, setSelectedProject] = useState(null);
  const [error, setError] = useState(null);
  const [isSmallScreen, setIsSmallScreen] = useState(false);
  const [isMediumScreen, setIsMediumScreen] = useState(false);

  useEffect(() => {
    const updateProjects = () => {
      const isSmallScreen = window.innerWidth < 767;
      const isMediumScreen = window.innerWidth >= 768;
      setIsSmallScreen(isSmallScreen);
      setIsMediumScreen(isMediumScreen);

      window.addEventListener('resize', updateProjects);

      return () => {
        window.removeEventListener('resize', updateProjects);
      };
    };
    updateProjects();
  }, []);

  useEffect(() => {
    const fetchProject = async () => {
      try {
        const foundProject = projects.find((project) => project.id === parseInt(projectId, 10));
        if (foundProject) {
          setSelectedProject(foundProject);
        } else {
          setError('Project not found');
        }
      } catch (error) {
        setError('Error fetching project data');
      }
    };

    window.scrollTo(0, 0);

    fetchProject();
  }, [projectId]);

  if (error) {
    return (
      <div>
        Error:
        {error}
      </div>
    );
  }

  if (!selectedProject) {
    return <div>Loading...</div>;
  }

  const handlePreviousProject = () => {
    const currentIndex = projects.findIndex((project) => project.id === selectedProject.id);
    const previousIndex = currentIndex === 0 ? projects.length - 1 : currentIndex - 1;
    setSelectedProject(projects[previousIndex]);
    window.scrollTo(0, 0);
  };

  const handleNextProject = () => {
    const currentIndex = projects.findIndex((project) => project.id === selectedProject.id);
    const nextIndex = currentIndex === projects.length - 1 ? 0 : currentIndex + 1;
    setSelectedProject(projects[nextIndex]);
    window.scrollTo(0, 0);
  };

  const animatePro = {
    isHidden: { opacity: 0, x: '-100%' },
    isVisible: (transit) => ({
      opacity: 1,
      x: 0,
      transition: { delay: transit },
    }),
  };

  return (
    <motion.section
      className={styles.container}
      initial={{ width: 0 }}
      animate={{ width: '100%' }}
      exit={{ x: window.innerWidth, transition: { duration: 0.2 } }}
      style={{ overflow: 'hidden' }}
    >
      <AnimatePresence initial={false}>
        <motion.section
          variants={animatePro}
          initial="isHidden"
          animate="isVisible"
          exit="isHidden"
          className={styles.section}
        >
          <article className={styles.header}>
            <h2 className={styles.title}>Projects</h2>
            <div className={styles.underline} />
          </article>
          <article className={styles.main}>
            {isSmallScreen && <h3 className={styles.title}>{selectedProject.title}</h3>}
            <img src={selectedProject.images[0]} className={styles.img} alt={selectedProject.title} />
            <div className={styles.info}>
              <article className={styles.content}>
                {isMediumScreen && <h3 className={styles.title}>{selectedProject.title}</h3>}
                <div className={styles.features}>
                  <p className={styles.text}>
                    <strong>
                      Project Name:
                    </strong>
                    {` ${selectedProject.name}`}
                  </p>
                  {selectedProject.details && Object.entries(selectedProject.details).map(([key, value]) => (
                    <p key={key} className={styles.text}>
                      <strong>
                        {`${key}: `}
                      </strong>
                      {value}
                    </p>
                  ))}
                </div>
              </article>
              <article className={styles.description}>
                <h4 className={styles.head}>Description</h4>
                <p className={styles.text}>{!selectedProject.description ? selectedProject.intro : selectedProject.description}</p>
              </article>
            </div>
          </article>
          <div className={styles.gallery}>
            {selectedProject.images.map((image, index) => (
              index !== 0 ? (
                <motion.img
                  initial={{ opacity: 0, y: 100 }}
                  whileInView={{ opacity: 1, y: 0 }}
                  transition={{ duration: 0.5 }}
                  key={image}
                  src={image}
                  alt={selectedProject.title}
                  className={styles.image}
                />
              ) : null
            ))}
          </div>
          <article className={styles.view}>
            <button type="button" className={styles.btn} onClick={handlePreviousProject}>
              <FaAngleDoubleLeft className={styles.ProjectIcon} />
              Previous project
            </button>
            <button type="button" className={styles.btn} onClick={handleNextProject}>
              Next project
              <FaAngleDoubleRight className={styles.ProjectIcon} />
            </button>
          </article>
        </motion.section>
      </AnimatePresence>
    </motion.section>
  );
};

export default ProjectFeature;
