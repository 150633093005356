/* eslint-disable linebreak-style */
import React, { useEffect } from 'react';
import { motion } from 'framer-motion';
import Project from '../../components/Project/Project';

const Projects = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <motion.div
      initial={{ width: 0 }}
      animate={{ width: '100%' }}
      exit={{ x: window.innerWidth, transition: { duration: 0.2 } }}
      style={{ overflow: 'hidden' }}
    >
      <Project home={false} />
    </motion.div>
  );
};

export default Projects;
