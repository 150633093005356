import React from 'react';
import { FaWhatsappSquare } from 'react-icons/fa';
import styles from './Chats.module.scss';

const Chat = () => {
  const handleChat = () => {
    const message = 'Hellp! Can I ask about your services, please?.';
    window.open(`https://wa.me/${+2348182643531}?text=${message}`, '_blank');
  };

  return (
    <div>
      <FaWhatsappSquare title="Got a question in mind?" onClick={handleChat} className={styles.scroll} />
    </div>
  );
};

export default Chat;
