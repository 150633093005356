/* eslint-disable max-len */
import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import PropTypes from 'prop-types';
import { motion } from 'framer-motion';
import { FaAngleDoubleRight } from 'react-icons/fa';
import styles from './Project.module.scss';
import projects from '../../data/projects';

const Project = ({ home }) => {
  const navigate = useNavigate();

  const [displayedProjects, setDisplayedProjects] = useState([]);
  const [showAllProjects, setShowAllProjects] = useState(false);
  const [isLargeScreen, setIsLargeScreen] = useState(false);

  const handleExploreMore = (projectId) => {
    navigate(`/projects/${projectId}`);
  };

  const toggleShowAll = () => {
    setShowAllProjects((prev) => !prev);
  };

  useEffect(() => {
    const updateProjects = () => {
      const isLargeScreen = window.innerWidth > 960;
      setIsLargeScreen(isLargeScreen);

      if (isLargeScreen || showAllProjects) {
        setDisplayedProjects(projects);
      } else {
        setDisplayedProjects(projects.slice(0, 3));
      }
    };

    updateProjects();

    window.addEventListener('resize', updateProjects);

    return () => {
      window.removeEventListener('resize', updateProjects);
    };
  }, [showAllProjects]);

  return (
    <section className={`${styles.ProjectDiv} ${!home ? styles.pad : ''}`} style={{ overflow: 'hidden' }}>
      <article className={`${styles.header} ${home ? '' : styles.start}`}>
        <h2 className={styles.title}>Our Projects</h2>
        {home && <div className={styles.underline} />}
      </article>
      <div className={styles.Projects}>
        {displayedProjects.map((project) => (
          <motion.article
            initial={{ opacity: 0, y: 100 }}
            whileInView={{
              opacity: 1,
              y: 0,
              transition: { duration: 0.2 },
            }}
            viewport={{ once: true }}
            title={project.description}
            onClick={() => handleExploreMore(project.id)}
            className={styles.Project}
            key={project.id}
          >
            <img
              src={project.images[0]}
              alt={project.name}
              className={styles.ProjectImg}
            />
            <div className={styles.ProjectInfo}>
              <h3 className={styles.ProjectName}>{project.name}</h3>
              <motion.p
                initial={{ opacity: 0, y: 50 }}
                whileInView={{ opacity: 1, y: 0, transition: { delay: 0.2 } }}
                viewport={{ once: true }}
                className={styles.ProjectDesc}
              >
                {project.intro}
              </motion.p>
              <motion.button
                type="button"
                initial={{ opacity: 0, x: -100 }}
                whileInView={{ opacity: 1, x: 0, transition: { delay: 0.3 } }}
                whileHover={{ scale: 1.1, color: 'rgba(160, 31, 240, 0.7)' }}
                whileTap={{ scale: 0.9, color: 'rgba(160, 31, 240, 0.5)' }}
                transition={{
                  duration: 0.8,
                  type: 'spring',
                  stiffness: 1000,
                }}
                className={styles.ProjectLink}
                onClick={() => handleExploreMore(project.id)}
              >
                Explore more
                <FaAngleDoubleRight className={styles.ProjectIcon} />
              </motion.button>
            </div>
          </motion.article>
        ))}
      </div>
      {!isLargeScreen && (
        <button type="button" className={styles.seeMoreBtn} onClick={toggleShowAll}>
          {showAllProjects ? 'View less' : 'View all Projects'}
        </button>
      )}
    </section>
  );
};

Project.defaultProps = {
  home: true,
};

Project.propTypes = {
  home: PropTypes.bool,
};

export default Project;
