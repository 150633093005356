/* eslint-disable arrow-body-style */
import React from 'react';
import { BrowserRouter } from 'react-router-dom';
// eslint-disable-next-line import/no-extraneous-dependencies
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Verified from './Verified';
import Nav from '../nav/Nav';
import Footer from '../components/Footer/Footer';

const Layout = () => {
  return (
    <BrowserRouter>
      <Nav />
      <ToastContainer theme="colored" position="top-right" />
      <Verified />
      <Footer />
    </BrowserRouter>
  );
};

export default Layout;
