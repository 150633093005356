import React, { useEffect } from 'react';
import { motion } from 'framer-motion';
import styles from './Policy.module.scss';

const ReturnPolicy = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <motion.section
      initial={{ width: 0 }}
      className={styles.container}
      animate={{ width: '100%' }}
      exit={{ x: window.innerWidth, transition: { duration: 0.2 } }}
      layout
    >
      <h1 className={styles.title}>Return policy</h1>
      <h4 className={styles.subtitle}>
        Thank you for shopping with us at Avilah Interiors. We want you
        to be delighted with your purchase. Hence, we ensure that all
        items are well-packaged, rightly tagged and delivered in good
        condition. However, If for any reason you received a
        damaged/broken/faulty item or you are not satisfied with your purchase,
        kindly note that the following policies apply:
      </h4>
      <ul className={styles.list}>
        <li className={styles.item}>
          Items may be returned within 15 days from the purchase date and must
          be unused, in their original packaging, and in resalable condition.
        </li>
        <li className={styles.item}>
          If you receive damaged, broken, or faulty items, it is necessary
          to provide a photograph of the item and promptly forward it to our
          customer service department upon delivery. Please be aware that items
          signed for and accepted in good condition upon delivery are not
          covered by this policy.
        </li>
        <li className={styles.item}>
          Breakable items should be returned in their original packaging with
          protective material to prevent damage during transit.
        </li>
        <li className={styles.item}>
          Proof of purchase is required for all returns
        </li>
        <li className={styles.item}>
          Refunds will be issued in the same form of payment used for the original purchase.
        </li>
        <li className={styles.item}>
          Shipping costs for returns are the responsibility of the customer, except in
          the case of damaged or broken items upon delivery.
        </li>
        <li className={styles.item}>
          Should you need to initiate a return or have inquiries concerning our return policy,
          please do not hesitate to contact our customer service team at
          <a href="tel:08182643531"> 08182643531.</a>
        </li>
      </ul>
      <p className={styles.note}>
        We appreciate your business and thank you for shopping with us.
      </p>
    </motion.section>
  );
};

export default ReturnPolicy;
