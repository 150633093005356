import React, { useEffect } from 'react';
import { motion } from 'framer-motion';
import styles from './Policy.module.scss';

const DeliveryPolicy = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <motion.section
      initial={{ width: 0 }}
      className={styles.container}
      animate={{ width: '100%' }}
      exit={{ x: window.innerWidth, transition: { duration: 0.2 } }}
      layout
    >
      <h1 className={styles.title}>Delivery Policy</h1>
      <h4 className={styles.subtitle}>
        We are committed to providing efficient and timely delivery service
        to all our customers. Please see our delivery policy below:
      </h4>
      <ul className={styles.list}>
        <li className={styles.item}>
          For residents in Lagos, we offer 24/48 hours delivery service.
          Orders placed within Lagos will be delivered within 24 to 48 hours
          from the time of purchase.
        </li>
        <li className={styles.item}>
          For customers outside Lagos and in other states, delivery may take 5
          to 10 working days from the date of purchase. Please note that
          delivery times may vary depending on the location.
        </li>
        <li className={styles.item}>
          Delivery charges will be calculated at the time of checkout
          based on the delivery address.
        </li>
        <li className={styles.item}>
          We partner with reliable courier services to ensure safe and secure
          delivery of your orders.
        </li>
        <li className={styles.item}>
          Once your order has been dispatched, you will receive a tracking
          number to monitor the status of your delivery.
        </li>
        <li className={styles.item}>
          In the event of any delays or issues with your delivery, please
          contact our customer service team at
          <a href="tel:08182643531"> 08182643531 </a>
          or
          <a href="mailto:info@avilahinteriors.com"> info@avilahinteriors.com.</a>
        </li>
      </ul>
      <p className={styles.note}>
        Thank you for choosing us for your interior accessory needs. We strive to
        make your shopping experience smooth and convenient.
      </p>
    </motion.section>
  );
};

export default DeliveryPolicy;
