import React, { useEffect } from 'react';
import { motion } from 'framer-motion';
import styles from './Policy.module.scss';

const PrivacyPolicy = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <motion.section
      initial={{ width: 0 }}
      className={styles.container}
      animate={{ width: '100%' }}
      exit={{ x: window.innerWidth, transition: { duration: 0.2 } }}
      layout
    >
      <h1 className={styles.title}>Privacy policy</h1>
      <h4 className={styles.subtitle}>
        At Avilah Interiors, we are committed to protecting the privacy and
        confidentiality of our customers&apos; personal information.
        Please read our privacy policy below to understand how we collect,
        use, and safeguard the information you provide to us:
      </h4>
      <ul className={styles.list}>
        <li className={styles.item}>
          <span>Information Collection: </span>
          We collect personal information such as name,
          email address, phone number, and address when you book consultations,
          place orders for furniture or purchase interior accessories on our
          website. This information is necessary to process your inquiries
          and orders efficiently.
        </li>
        <li className={styles.item}>
          <span>Use of Information: </span>
          The personal information you provide is used solely for the purpose
          of booking consultations, processing orders, and communicating with
          you regarding your inquiries or purchases and our brand marketing.
          We do not share your information with third parties for other marketing purposes.
        </li>
        <li className={styles.item}>
          <span>Data Security: </span>
          We implement appropriate security measures to protect your personal
          information from unauthorized access, disclosure, alteration, or
          destruction. Your data is stored securely and accessed only by
          authorized personnel.
        </li>
        <li className={styles.item}>
          <span>Cookies: </span>
          Our website may use cookies to enhance your browsing experience.
          These cookies do not collect personal information and are used
          for statistical analysis and website functionality purposes.
        </li>
        <li className={styles.item}>
          <span>Consent: </span>
          Refunds will be issued in the same form of payment used for the original purchase.
        </li>
        <li className={styles.item}>
          <span>Access and Correction: </span>
          You have the right to access, correct, or delete your personal
          information held by us. If you would like to update your information
          or have any concerns about your privacy, please get in touch with us at
          <a href="tel:08182643531"> 08182643531 </a>
          or
          <a href="mailto:info@avilahinteriors.com"> info@avilahinteriors.com.</a>
        </li>
        <li className={styles.item}>
          <span>Updates to Privacy Policy: </span>
          We may update our privacy policy from time to time to reflect changes
          in our practices or legal requirements.
        </li>
        <li className={styles.item}>
          Updates will be posted on our website, and we recommend reviewing this
          policy periodically.
        </li>
        <li className={styles.item}>
          We take your privacy seriously and strive to maintain the highest standards
          of data protection. If you have any questions or concerns about our privacy
          policy, please do not hesitate to contact us.
        </li>
      </ul>
      <p className={styles.note}>
        Thank you for entrusting us with your interior design needs.
      </p>
    </motion.section>
  );
};

export default PrivacyPolicy;
