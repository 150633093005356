/* eslint-disable linebreak-style */
/* eslint-disable arrow-body-style */
import React from 'react';
import { Link } from 'react-router-dom';
import { motion } from 'framer-motion';
import styles from './Interior.module.scss';
import check from '../../images/check.gif';

const ThankYou = () => {
  return (
    <motion.section
      initial={{ width: 0 }}
      animate={{ width: '100%' }}
      exit={{ x: window.innerWidth, transition: { duration: 0.2 } }}
      style={{ overflow: 'hidden' }}
      className={styles.container}
    >
      <iframe
        title="Thank you"
        frameBorder="none"
        src="https://lottie.host/embed/10a6d469-67a6-497f-8991-6ec3f464020a/LprzndGVtE.json"
        className={styles.lottie}
      />
      <article className={styles.content}>
        <h2 className={styles.title}>Thank you!</h2>
        <img
          src={check}
          alt="Thank you"
          className={styles.image}
        />
        <h4 className={styles.titleB}>For sharing your project with Avilah interiors!</h4>
        <p className={styles.text}>Our Top Agent will reachout to you shortly</p>
      </article>
      <Link to="/" className={styles.btn}>Go back Home</Link>
    </motion.section>
  );
};

export default ThankYou;
