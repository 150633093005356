import {
  MdOutlineLocationOn,
  MdOutlineMarkEmailUnread,
} from 'react-icons/md';
import { FaRegCalendarCheck } from 'react-icons/fa';
import { FiPhone } from 'react-icons/fi';
import styles from '../pages/Contact/Contact.module.scss';

const contact = [
  {
    id: 1,
    icon: <MdOutlineLocationOn className={styles.icon} />,
    title: 'Address',
    paraA: 'Lekki, Nigeria',
    paraB: 'Open: Monday – Saturday (09:00am – 06:00pm)',
  },
  {
    id: 2,
    icon: <FiPhone className={styles.icon} />,
    title: 'Phone numbers',
    paraA: '+234 818 264 3531',
  },
  {
    id: 3,
    icon: <FaRegCalendarCheck className={styles.icon} />,
    title: 'Book an Appointment',
    paraA: 'Work one on one with us to receive personalized advice, in store and virtually.',
  },
  {
    id: 4,
    icon: <MdOutlineMarkEmailUnread className={styles.icon} />,
    title: 'Email address',
    paraA: 'info@avilahinteriors.com',
  },
];

export default contact;
