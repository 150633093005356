/* eslint-disable linebreak-style */
/* eslint-disable max-len */
/* eslint-disable no-nested-ternary */
/* eslint-disable jsx-a11y/control-has-associated-label */
/* eslint-disable react/no-array-index-key */
/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable import/no-extraneous-dependencies */
import React, { useCallback, useEffect, useState } from 'react';
import { motion } from 'framer-motion';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { useFormik } from 'formik';
import { useForm } from '@formspree/react';
import { PhoneInput } from 'react-international-phone';
// eslint-disable-next-line import/no-extraneous-dependencies
import { useDropzone } from 'react-dropzone';
import { LuImage } from 'react-icons/lu';
import PropTypes from 'prop-types';
import { BsDownload } from 'react-icons/bs';
import { MdOutlineNavigateNext, MdOutlineNavigateBefore } from 'react-icons/md';
import styler from './FileUploader.module.scss';
import 'react-international-phone/style.css';
import styles from './Furniture.module.scss';
import { home, office } from '../../assets/data/furniture';

const assets = [...home, ...office];

const FileUploader = ({ setFieldValue }) => {
  const onDrop = useCallback((acceptedFiles) => {
    setFieldValue('sample', acceptedFiles[0]);
    setFieldValue('sampleUrl', URL.createObjectURL(acceptedFiles[0]));
  }, [setFieldValue]);

  const { getRootProps, getInputProps } = useDropzone({ onDrop });
  return (
    <div {...getRootProps({ className: styler.dropzone })}>
      <input {...getInputProps()} />
      <p>Drag &apos;n&apos; drop or click to select an image</p>
      <LuImage size="3em" />
    </div>
  );
};

const validate = ((values) => {
  const errors = {};

  if (!values.name) {
    errors.name = 'Name is required';
  }

  if (!values.phone) {
    errors.phone = 'Must be valid number e.g. +2348012345678';
  } else if (!/^\+234(\s?\d){10}$/.test(values.phone)) {
    errors.phone = 'Invalid phone number';
  }

  if (!values.email) {
    errors.email = 'Email is required';
  } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.email)) {
    errors.email = 'Invalid email address';
  }

  if (!values.preferredContact) {
    errors.preferredContact = 'How would you like to be contacted?';
  }

  return errors;
});

const Furniture = () => {
  const navigate = useNavigate();
  const [state, handleSubmit] = useForm('xvgonzdn');
  const [currentIndex, setCurrentIndex] = useState(0);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const handleNext = () => setCurrentIndex((currentIndex + 1) % assets.length);
  const handlePrev = () => setCurrentIndex((currentIndex - 1 + assets.length) % assets.length);

  const formik = useFormik({
    initialValues: {
      name: '',
      phone: '',
      email: '',
      preferredContact: '',
      type: '',
      function: '',
      materials: '',
      dimension: '',
      design: '',
      comments: '',
      sample: null,
      sampleUrl: '',
    },
    validate,
    onSubmit: async (values) => {
      if (!values) {
        toast.error('Please fill in the required fields');
      } else {
        await handleSubmit(values);
        navigate('/thank-you');
      }
    },
  });

  const handleImageClick = (index) => {
    setCurrentIndex(index);
  };

  return (
    <motion.section
      className={styles.interior}
      initial={{ width: 0 }}
      animate={{ width: '100%' }}
      exit={{ x: window.innerWidth, transition: { duration: 0.2 } }}
      style={{ overflow: 'hidden' }}
    >
      <article className={styles.intro}>
        <p className={styles.formPara}>
          At Avilah interiors, we specialize in delivering exceptional interior
          design and custom furniture solutions. Our experts  are here to bring
          your vision to life with personalized designs and professional craftsmanship.
          Let’s create beautiful spaces together!
        </p>
        <div className="carousel mobile">
          <button type="button" className="abButton prev" onClick={handlePrev}><MdOutlineNavigateBefore /></button>
          <div className="carousel-images">
            {assets.map((img, index) => {
              const isCenter = index === currentIndex;
              const isLeft = index === (currentIndex - 1 + assets.length) % assets.length;
              const isRight = index === (currentIndex + 1) % assets.length;

              return (
                <motion.div
                  key={index + 1}
                  className={`image-container ${isCenter ? 'center' : isLeft || isRight ? 'side' : 'hidden'}`}
                  initial={{ scale: 0.7, opacity: 0.5 }}
                  animate={{
                    scale: isCenter ? 1.1 : 0.5,
                    opacity: isCenter ? 1 : 0.9,
                    x: isCenter ? 0 : isLeft ? -200 : isRight ? 200 : 0,
                  }}
                  transition={{
                    duration: 0.5,
                  }}
                >
                  <a href={img.img} download={img.desc} className="download">
                    Download
                    <BsDownload />
                  </a>
                  <img src={img.img} alt={`Slide ${index}`} />
                </motion.div>
              );
            })}
          </div>
          <button type="button" className="abButton next" onClick={handleNext}><MdOutlineNavigateNext /></button>
        </div>
        <div className="carousel desktop">
          <button type="button" className="abButton prev" onClick={handlePrev}>
            <MdOutlineNavigateBefore />
          </button>
          <div className="carousel-images">
            {assets.map((img, index) => {
              const diff = index - currentIndex;
              const adjustedDiff = diff > assets.length / 2 ? diff - assets.length : diff < -assets.length / 2 ? diff + assets.length : diff;
              const isCenter = adjustedDiff === 0;
              const distance = Math.abs(adjustedDiff);
              const scale = 1.3 - Math.min(distance, 3) * 0.17;
              const x = adjustedDiff * 200;

              return (
                <motion.div
                  key={index}
                  className={`image-container ${isCenter ? 'center' : 'side'}`}
                  style={{ zIndex: assets.length - distance, cursor: 'pointer' }}
                  animate={{
                    scale,
                    x,
                  }}
                  transition={{
                    duration: 0.3,
                  }}
                  onClick={() => handleImageClick(index)}
                >
                  <a href={img.img} download={img.desc} className="download">
                    Download
                    <BsDownload />
                  </a>
                  <img src={img.img} alt={`Slide ${index}`} />
                </motion.div>
              );
            })}
          </div>
          <button type="button" className="abButton next" onClick={handleNext}>
            <MdOutlineNavigateNext />
          </button>
        </div>
      </article>
      <form className={styles.form} onSubmit={formik.handleSubmit}>
        <div className={styles.subGroup}>
          <div className={styles.formGroup}>
            <h2 className={styles.formHead}>Contact Details</h2>
            <input
              type="text"
              className={styles.formField}
              name="name"
              placeholder="Name"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.name}
            />
            {formik.touched.name && formik.errors.name ? (
              <small className={styles.error}>
                <span style={{ color: 'red' }}>* </span>
                {formik.errors.name}
              </small>
            ) : null}
            <PhoneInput
              placeholder="Phone number"
              value={formik.values.phone}
              onChange={(phone) => formik.setFieldValue('phone', phone)}
              onBlur={() => formik.setFieldTouched('phone', true)}
              defaultCountry="ng"
              international
            />
            {formik.touched.phone && formik.errors.phone ? (
              <small className={styles.error}>
                <span style={{ color: 'red' }}>* </span>
                {formik.errors.phone}
              </small>
            ) : null}
            <input
              type="email"
              className={styles.formField}
              name="email"
              placeholder="Email address"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.email}
            />
            {formik.touched.email && formik.errors.email ? (
              <small className={styles.error}>
                <span style={{ color: 'red' }}>* </span>
                {formik.errors.email}
              </small>
            ) : null}
            <input
              type="text"
              name="preferredContact"
              className={styles.formField}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              placeholder="Preferred method of contact"
              value={formik.values.preferredContact}
            />
            {formik.touched.preferredContact && formik.errors.preferredContact ? (
              <small className={styles.error}>
                <span style={{ color: 'red' }}>* </span>
                {formik.errors.preferredContact}
              </small>
            ) : null}
            <div className={styler.subGroup}>
              <div className={styler.formGroup}>
                <h3 className={styles.formHeadA}>
                  Please upload an image/inspiration of the furniture
                  type you want below:
                </h3>
                <FileUploader setFieldValue={formik.setFieldValue} />
                {formik.values.sample && (
                  <div className={styler.preview}>
                    <img
                      src={formik.values.sampleUrl}
                      className={styler.img}
                      alt={formik.values.sample.name}
                    />
                  </div>
                )}
                <button type="submit" className={styles.btnB}>Submit</button>
              </div>
            </div>
          </div>
          <div className={styles.formGroup}>
            <h2 className={styles.formHead}>Furniture specification</h2>
            <input
              type="text"
              name="type"
              className={styles.formField}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.type}
              placeholder="Furniture type  (e.g upholstery or woodwork)"
            />
            <input
              type="text"
              name="function"
              className={styles.formField}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.function}
              placeholder="Function (e.g chairs, tables, bedframes, cabinets, etc.)"
            />
            <input
              type="text"
              name="dimension"
              className={styles.formField}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.dimension}
              placeholder="Dimension ( e.g a two-seater, queen’s beds, full kitchen, TV console, etc.)"
            />
            <input
              type="text"
              name="materials"
              className={styles.formField}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.materials}
              placeholder="Materials (e.g fabric, leather, solid wood, rustic, etc.)"
            />
            <input
              type="text"
              name="design"
              className={styles.formField}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.design}
              placeholder="Design (e.g minimalist or custom-made)"
            />
            <textarea
              className={styles.formFieldArea}
              name="comments"
              placeholder="Additional information"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.comments}
            />
          </div>
        </div>

        <div className={styles.subGroup}>
          <div className={styles.formGroup}>
            <h3 className={styles.formHeadA}>
              Please upload an image/inspiration of the furniture
              type you want below:
            </h3>
            <FileUploader setFieldValue={formik.setFieldValue} />
            {formik.values.sample && (
              <div className={styler.preview}>
                <img
                  src={formik.values.sampleUrl}
                  className={styler.img}
                  alt={formik.values.sample.name}
                />
              </div>
            )}
            <button type="submit" disabled={state.submitting} className={styles.btnB}>Submit</button>
          </div>
        </div>
        <button type="submit" disabled={state.submitting} className={styles.btn}>Submit</button>
      </form>
    </motion.section>
  );
};

FileUploader.propTypes = {
  setFieldValue: PropTypes.func.isRequired,
};

export default Furniture;
