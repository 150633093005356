/* eslint-disable arrow-body-style */
import React from 'react';
import { Link } from 'react-router-dom';
import { motion } from 'framer-motion';
import styles from './Contact.module.scss';
import contact from '../../data/contact';
import SlideContact from './SlideContact';

const Contact = () => {
  return (
    <motion.section
      initial={{ width: 0 }}
      animate={{ width: '100%' }}
      exit={{ x: window.innerWidth, transition: { duration: 0.2 } }}
      style={{ overflow: 'hidden' }}
      className={styles.section}
    >
      <SlideContact />
      <article className={styles.header}>
        <h2 className={styles.title}>Contact us</h2>
      </article>
      <article className={styles.content}>
        {contact.map((item) => {
          const {
            id, icon, title, paraA, paraB,
          } = item;

          return title !== 'Book an Appointment' ? (
            <article key={id} className={styles.contact}>
              <div className={styles.iconDiv}>{icon}</div>
              <div className={styles.div}>
                <h3 className={styles.title}>{title}</h3>
                <p className={styles.text}>{paraA}</p>
                {paraB && <p className={styles.textb}>{paraB}</p>}
              </div>
            </article>
          ) : (
            <Link to="/consultation/interior-design" className={styles.contact} key={id}>
              <div className={styles.iconDiv}>{icon}</div>
              <div className={styles.div}>
                <h3 className={styles.title}>{title}</h3>
                <p className={styles.text}>{paraA}</p>
                {paraB && <p className={styles.text}>{paraB}</p>}
              </div>
            </Link>
          );
        })}
      </article>
    </motion.section>
  );
};

export default Contact;
