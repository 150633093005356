/* eslint-disable jsx-a11y/control-has-associated-label */
/* eslint-disable no-nested-ternary */
/* eslint-disable max-len */
/* eslint-disable arrow-body-style */
import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { motion, AnimatePresence } from 'framer-motion';
import PropTypes from 'prop-types';
import { MdKeyboardArrowLeft, MdKeyboardArrowRight } from 'react-icons/md';
import styles from './Service.module.scss';
import services from '../../data/services';

const Service = ({ home }) => {
  const [currentIndex, setCurrentIndexes] = useState(Array(services.length).fill(0));

  const handleNextImage = (index) => {
    setCurrentIndexes((prevIndexes) => prevIndexes.map((currentIndex, i) => (i === index ? (currentIndex + 1) % services[index].image.length : currentIndex)));
  };

  const handlePrevImage = (index) => {
    setCurrentIndexes((prevIndexes) => prevIndexes.map((currentIndex, i) => (i === index ? (currentIndex === 0 ? services[index].image.length - 1 : currentIndex - 1) : currentIndex)));
  };

  return (
    <section className={`${styles.container} ${!home ? styles.pad : ''}`} style={{ overflow: 'hidden' }}>
      <article className={`${styles.header} ${home ? '' : styles.start}`}>
        <h2 className={styles.title}>Our Services</h2>
        {home && <div className={styles.underline} />}
      </article>
      <article className={`${styles.content} ${!home ? styles.flexContent : ''}`}>
        {home ? (
          services.map((perk) => {
            return (
              <Link
                to={perk.path}
                key={perk.id}
                className={styles.linker}
              >
                <motion.article
                  className={styles.perk}
                  initial={{ opacity: 0, y: 50 }}
                  whileInView={{ opacity: 1, y: 0, transition: { duration: 0.5 } }}
                >
                  <img src={perk.icon} className={styles.icon} alt={perk.title} />
                  <div className={styles.div}>
                    <h5 className={styles.heading}>{perk.title}</h5>
                  </div>
                </motion.article>
              </Link>
            );
          })
        ) : (
          services.map((perk, index) => {
            return (
              <article
                className={styles.perker}
                key={perk.id}
              >
                <button type="button" className={styles.prevButton} onClick={() => handlePrevImage(index)}>
                  <MdKeyboardArrowLeft className={styles.icon} />
                </button>
                <button type="button" className={styles.nextButton} onClick={() => handleNextImage(index)}>
                  <MdKeyboardArrowRight className={styles.icon} />
                </button>
                <AnimatePresence>
                  <motion.img
                    src={perk.image[currentIndex[index]]}
                    className={styles.img}
                    alt={perk.title}
                    initial={{ x: 300, opacity: 0 }}
                    animate={{ x: 0, opacity: 1 }}
                    exit={{ x: -300, opacity: 0 }}
                    transition={{
                      duration: 0.2, type: 'spring', stiffness: 300,
                    }}
                  />
                </AnimatePresence>
                <div className={styles.div}>
                  <h4 className={styles.heading}>{perk.title}</h4>
                  <p className={styles.text}>{perk.details}</p>
                  <Link to={perk.action} className={styles.btn}>{perk.button}</Link>
                </div>
              </article>
            );
          })
        )}
      </article>
    </section>
  );
};

Service.defaultProps = {
  home: true,
};

Service.propTypes = {
  home: PropTypes.bool,
};

export default Service;
