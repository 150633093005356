import React, { useEffect } from 'react';
import { motion } from 'framer-motion';
import LandingPage from './LandingPage';
import Stats from '../../components/Stats/Stats';
import Why from '../../components/Why/Why';
import Service from '../../components/Service/Service';
import Project from '../../components/Project/Project';
import Testimonial from '../../components/Testimonial/Testimonial';

const Home = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <motion.div
      initial={{ width: 0 }}
      animate={{ width: '100%' }}
      exit={{ x: window.innerWidth, transition: { duration: 0.2 } }}
    >
      <LandingPage />
      <Stats />
      <Why />
      <Service />
      <Project />
      <Testimonial />
    </motion.div>
  );
};

export default Home;
