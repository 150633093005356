import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { TiMail } from 'react-icons/ti';
import { CiLocationOn } from 'react-icons/ci';
import { SlCallEnd } from 'react-icons/sl';
import {
  FaFacebook,
  FaInstagram,
  FaWhatsapp,
} from 'react-icons/fa';
import styles from './Footer.module.scss';

const Footer = () => {
  const [isLargeScreen, setIsLargeScreen] = useState(false);

  useEffect(() => {
    const updateScreen = () => {
      const isLargeScreen = window.innerWidth > 955;
      setIsLargeScreen(isLargeScreen);
    };
    updateScreen();

    window.addEventListener('resize', updateScreen);

    return () => {
      window.removeEventListener('resize', updateScreen);
    };
  }, []);

  const currentYear = new Date().getFullYear();

  return (
    <section className={styles.footer}>
      <article className={styles.footerTop}>
        <div className={styles.footerContactContent}>
          <menu className={styles.footerContactMenu}>
            <li className={styles.footerContactItem}>Quick links</li>
            <li className={styles.footerItem}>
              <Link to="/" className={styles.footerLink}>
                Home
              </Link>
            </li>
            <li className={styles.footerItem}>
              <Link to="/about-us" className={styles.footerLink}>
                About us
              </Link>
            </li>
            <li className={styles.footerItem}>
              <Link to="/services" className={styles.footerLink}>
                Services
              </Link>
            </li>
            <li className={styles.footerItem}>
              <Link to="/projects" className={styles.footerLink}>
                Projects
              </Link>
            </li>
            <li className={styles.footerItem}>
              <Link to="https://shop.avilahinteriors.com" className={styles.footerLink}>
                Shop
              </Link>
            </li>
          </menu>

          <menu className={styles.footerContactMenu}>
            <li className={styles.footerContactItem}>Payment Method</li>
            <li className={styles.footerItem}>Credit Card</li>
            <li className={styles.footerItem}>Bank Transfer</li>
            <li className={styles.footerItem}>Paypal</li>
          </menu>

          <menu className={styles.footerContactMenu}>
            <li className={styles.footerContactItem}>Policies</li>
            <li className={styles.footerItem}>
              <a href="/privacy-policy" className={styles.footerLink}>
                Privacy
              </a>
            </li>
            <li className={styles.footerItem}>
              <a href="/return-policy" className={styles.footerLink}>
                Return
              </a>
            </li>
            <li className={styles.footerItem}>
              <a href="/delivery-policy" className={styles.footerLink}>
                Terms
              </a>
            </li>
          </menu>
          {isLargeScreen && (
          <div className={styles.footerContact}>
            <div className={styles.footerText}>
              <h4 className={styles.footerSocialItem}>Contact Us</h4>
              <article className={styles.footerLink}>
                <CiLocationOn className={styles.icon} />
                <p className={styles.text}>
                  Lekki, Lagos Nigeria.
                </p>
              </article>
              <article className={styles.footerLink}>
                <SlCallEnd className={styles.icon} />
                <div className={styles.contactDiv}>
                  <a href="tel:+2348182643531" className={styles.text}>+234 818 264 3531</a>
                </div>
              </article>
              <article className={styles.footerLink}>
                <TiMail className={styles.icon} />
                <a
                  href="mailto:info@avilahinteriors.com"
                  className={styles.text}
                >
                  info@avilahinteriors.com
                </a>
              </article>
            </div>

            <ul className={styles.footerSocialLinks}>
              <li className={styles.footerSocialLinkItem}>
                <Link to="https://www.instagram.com" className={styles.footerSocialLink}>
                  <FaInstagram className="icon" />
                </Link>
              </li>
              <li className={styles.footerSocialLinkItem}>
                <Link to="https://www.facebook.com/" className={styles.footerSocialLink}>
                  <FaFacebook className={styles.icon} />
                </Link>
              </li>
              <li className={styles.footerSocialLinkItem}>
                <Link to="https://wa.me/2348182643531" className={styles.footerSocialLink}>
                  <FaWhatsapp className={styles.icon} />
                </Link>
              </li>
            </ul>
          </div>
          )}
        </div>
        {!isLargeScreen && (
        <article className={styles.footerSocial}>
          <div className={styles.footerContact}>
            <div className={styles.footerText}>
              <h4 className={styles.footerSocialItem}>Contact us</h4>
              <article className={styles.footerLink}>
                <CiLocationOn className={styles.icon} />
                <p className={styles.text}>
                  Lekki, Lagos Nigeria.
                </p>
              </article>
              <article className={styles.footerLink}>
                <SlCallEnd className={styles.icon} />
                <div className={styles.contactDiv}>
                  <a href="tel:+2348182643531" className={styles.text}>+234 818 264 3531</a>
                </div>
              </article>
              <article className={styles.footerLink}>
                <TiMail className={styles.icon} />
                <a
                  href="mailto:info@avilahinteriors.com"
                  className={styles.text}
                >
                  info@avilahinteriors.com
                </a>
              </article>
            </div>

            <ul className={styles.footerSocialLinks}>
              <li className={styles.footerSocialLinkItem}>
                <Link to="https://www.instagram.com" className={styles.footerSocialLink}>
                  <FaInstagram className="icon" />
                </Link>
              </li>
              <li className={styles.footerSocialLinkItem}>
                <Link to="https://www.facebook.com/" className={styles.footerSocialLink}>
                  <FaFacebook className={styles.icon} />
                </Link>
              </li>
              <li className={styles.footerSocialLinkItem}>
                <Link to="https://wa.me/2348182643531" className={styles.footerSocialLink}>
                  <FaWhatsapp className={styles.icon} />
                </Link>
              </li>
            </ul>
          </div>
        </article>
        )}
      </article>
      <small className={styles.footerBottom}>
        <p className={styles.copyRight}>
          &copy;
          {` ${currentYear} `}
          Avilah Interiors
        </p>
      </small>
    </section>
  );
};

export default Footer;
