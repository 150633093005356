import init from '../images/init.svg';
import ideate from '../images/ideation.svg';
import implement from '../images/implement.svg';
import final from '../images/final.svg';
import install from '../images/install.svg';

const process = [
  {
    id: 1,
    badge: init,
    title: 'Initial consultation',
    text: 'Meet with client to discuss project goals',
  },
  {
    id: 2,
    badge: ideate,
    title: 'Idea & concept',
    text: 'Create design plan/moodboard or 3D',
  },
  {
    id: 3,
    badge: implement,
    title: 'Implementation',
    text: 'Project management process including necessary adjustments',
  },
  {
    id: 4,
    badge: install,
    title: 'Installation',
    text: 'Furniture, lightings, decor, etc.',
  },
  {
    id: 5,
    badge: final,
    title: 'Final Walk-through & Handover',
    text: 'This is the final stage',
  },
];

export default process;
