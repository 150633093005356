/* eslint-disable import/no-extraneous-dependencies */
/* eslint-disable arrow-body-style */
/* eslint-disable import/extensions */
import React from 'react';
import CountUp from 'react-countup';
import { motion } from 'framer-motion';
import stats from '../../data/stats.js';
import styles from './Stats.module.scss';

const Stats = () => {
  return (
    <section className={styles.stats} style={{ overflow: 'hidden' }}>
      {stats.map((stat) => (
        <motion.article
          initial={{ opacity: 0, x: 100 }}
          whileInView={{ opacity: 1, x: 0 }}
          transition={{ duration: 0.3, type: 'spring', stiffness: 150 }}
          key={stat.id}
          className={styles.stat}
        >
          <h4 className={styles.number}>
            <CountUp start={0} end={stat.value} separator="," decimal="." duration={20} />
            <span className={styles.lilac}>+</span>
          </h4>
          <p className={styles.text}>{stat.title}</p>
        </motion.article>
      ))}
    </section>
  );
};

export default Stats;
