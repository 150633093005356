import React from 'react';
import Card from 'react-bootstrap/Card';
import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';
import styles from './Testimonial.module.scss';
import reviews from '../../data/reviews';
import quote from '../../images/quote.png';

const Testimonial = () => {
  const configure = () => ({
    extraLarge: {
      breakpoint: { max: 4000, min: 1300 },
      items: 3,
    },

    desktop: {
      breakpoint: { max: 1290, min: 956 },
      items: 2,
    },

    tablet: {
      breakpoint: { max: 955, min: 768 },
      items: 2,
    },

    mobile: {
      breakpoint: { max: 767, min: 0 },
      items: 1,
    },
  });

  return (
    <section className={styles.container}>
      <article className={styles.header}>
        <h2 className={styles.title}>What Clients Say</h2>
        <div className={styles.underline} />
      </article>

      <article className={styles.reviewDiv}>
        <Carousel
          autoPlay
          autoPlaySpeed={8000}
          infinite
          className={styles.carousel}
          keyBoardControl
          responsive={configure()}
        >
          {reviews.map((review) => (
            <Card className={styles.reviewCard} key={review.id}>
              <img src={quote} alt="" className={styles.reviewRating} />
              <Card.Body className={styles.reviewInfo}>
                <Card.Text className={styles.reviewText}>{review.text}</Card.Text>
                <Card.Title className={styles.reviewName}>
                  <span className={styles.arrow}>- </span>
                  {review.name}
                  , Client
                </Card.Title>
              </Card.Body>
            </Card>
          ))}
        </Carousel>
      </article>
    </section>
  );
};

export default Testimonial;
