import React from 'react';
import { Routes, Route, useLocation } from 'react-router-dom';
import { AnimatePresence } from 'framer-motion';
import Home from '../pages/Home/Home';
import About from '../pages/About/About';
import Contact from '../pages/Contact/Contact';
import Services from '../pages/Services/Services';
import Interior from '../components/Forms/Interior';
import Furniture from '../components/Forms/Furniture';
import ThankYou from '../components/Forms/ThankYou';
import PrivacyPolicy from '../components/Policy/PrivacyPolicy';
import DeliveryPolicy from '../components/Policy/DeliveryPolicy';
import ReturnPolicy from '../components/Policy/ReturnPolicy';
import Projects from '../pages/Project/Project';
import ProjectFeature from '../pages/Project/ProjectFeature';

const Verified = () => {
  const location = useLocation();

  return (
    <AnimatePresence>
      <Routes location={location} key={location.pathname}>
        <Route path="/" element={<Home />} />
        <Route path="/about-us" element={<About />} />
        <Route path="/contact-us" element={<Contact />} />
        <Route path="/services" element={<Services />} />
        <Route path="/consultation/interior-design" element={<Interior />} />
        <Route path="/consultation/furniture-making" element={<Furniture />} />
        <Route path="/thank-you" element={<ThankYou />} />
        <Route path="/projects" element={<Projects />} />
        <Route path="/projects/:projectId" element={<ProjectFeature />} />
        <Route path="/privacy-policy" element={<PrivacyPolicy />} />
        <Route path="/delivery-policy" element={<DeliveryPolicy />} />
        <Route path="/return-policy" element={<ReturnPolicy />} />
      </Routes>
    </AnimatePresence>
  );
};

export default Verified;
