import React, { useEffect } from 'react';
import { motion } from 'framer-motion';
import Service from '../../components/Service/Service';

const Services = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <motion.div
      style={{ overflow: 'hidden' }}
      initial={{ width: 0 }}
      animate={{ width: '100%' }}
      exit={{ x: window.innerWidth, transition: { duration: 0.2 } }}
    >
      <Service home={false} />
    </motion.div>
  );
};

export default Services;
